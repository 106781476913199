import type { KeyboardEvent } from 'react';
import { useEffect, useRef, useState } from 'react';
import { Form, Link } from '@remix-run/react';
import { PassphraseInput } from './passphrase-input';
import { useRootLoaderData } from '~/root';
import { PrimaryButton } from './primary-button';
import { CopyTextButton } from './copy-text-button';
import Modal from './modal';
import { twMerge } from 'tailwind-merge';
import { authenticateForDesktop } from '~/services/desktop-auth.client';
import invariant from 'tiny-invariant';

export function DesktopAuthModal({ onClose }: { onClose: () => void }) {
  const { user } = useRootLoaderData();
  invariant(user, 'User is required');

  const launchRef = useRef<HTMLButtonElement | null>(null);

  const [token, setToken] = useState<string | null>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [passphrase, setPassphrase] = useState('');

  const isAuthenticated = Boolean(token);

  useEffect(() => {
    let isMounted = true;

    const fetchClientData = async () => {
      isMounted && setLoading(true);

      const data = await authenticateForDesktop({
        email: user.email,
        saltEnc: user.saltEnc,
        encDriverKey: user.encDriverKey,
        encryptionEnabled: user.encryptionEnabled,
        encPrivateKey: user.encPrivateKey,
        encSymmetricKey: user.encSymmetricKey,
      });

      if (!isMounted) {
        return;
      }

      if ('errors' in data) {
        setError(data.errors?.[0]);
        setLoading(false);
        return;
      }

      if ('token' in data) {
        setToken(data.token);
        setLoading(false);
        return;
      }
    };

    if (!user?.encryptionEnabled) {
      return;
    }

    fetchClientData();

    return () => {
      isMounted = false;
    };
  }, [user]);

  const handleContinue = async () => {
    invariant(user, 'User is required');

    if (!passphrase) {
      setError('Passphrase must be provided');
      return;
    }

    setLoading(true);

    const data = await authenticateForDesktop({
      email: user.email,
      saltEnc: user.saltEnc,
      passphrase,
      encDriverKey: user.encDriverKey,
      encryptionEnabled: user.encryptionEnabled,
      encPrivateKey: user.encPrivateKey,
      encSymmetricKey: user.encSymmetricKey,
    });

    if ('errors' in data) {
      setError(data.errors?.[0]);
      setLoading(false);
      return;
    }

    if ('token' in data) {
      setToken(data.token);
      setLoading(false);
      return;
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      launchRef.current?.focus();
    }
  }, [isAuthenticated]);

  const handleLaunch = () => {
    const insomniaAppURI = new URL(`${window.ENV.INSOMNIA_APP_URI}app/auth/finish?box=${token}`);
    window.location.assign(insomniaAppURI);
    onClose();
  };

  if (user?.encryptionEnabled) {
    return (
      <Modal isOpen={true} title="Launch Insomnia desktop" onCancel={onClose} className="max-w-[600px]">
        <div className="flex flex-col">
          <div className="flex flex-col">
            <p className="m-0 text-[14px] font-normal leading-[18px] tracking-[-0.25px] text-[#000000d9]">
              {loading
                ? 'Authenticating your account. Please wait...'
                : error
                ? error ?? 'Something went wrong. Please contact the support.'
                : 'You are successfully authenticated. You can click Launch button or manually enter the token.'}
            </p>
            <div className={twMerge('flex items-end justify-between', error ? 'mt-[32px]' : 'mt-[16px]')}>
              <div className="flex gap-[10px]">
                <PrimaryButton
                  type="button"
                  onPress={handleLaunch}
                  isDisabled={!isAuthenticated || loading || Boolean(error)}
                  ref={launchRef}
                  className="w-[200px]"
                >
                  Launch
                </PrimaryButton>
              </div>
              <CopyTextButton value={token} isDisabled={Boolean(error) || loading}>
                Manually enter token
              </CopyTextButton>
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  return (
    <Modal
      isOpen={true}
      title={!isAuthenticated ? 'Enter your existing passphrase' : 'Launch Insomnia desktop'}
      className="max-w-[600px]"
      onCancel={() => {
        sessionStorage.removeItem('loginKey');
        onClose();
      }}
    >
      <div className="flex flex-col">
        <p className="m-0 text-[14px] font-normal leading-[18px] tracking-[-0.25px] text-[#000000d9]">
          {!isAuthenticated
            ? 'Enter the passphrase that you have created when you signed up on Insomnia, which is being used to secure your data with end-to-end encryption (E2EE).'
            : 'You are successfully authenticated. You can click Launch button or manually enter the token.'}
        </p>

        {!isAuthenticated && (
          <Form name="passphraseForm" className="mt-[20px]">
            <PassphraseInput
              name="passphrase"
              autoFocus
              onChangePassphrase={setPassphrase}
              isInvalid={Boolean(error)}
              errorMessage={error}
              onInput={() => {
                if (error) {
                  setError(undefined);
                }
              }}
              onKeyDown={async (e: KeyboardEvent) => {
                if (e.code === 'Enter' || e.code === 'NumpadEnter') {
                  e.preventDefault();
                  e.stopPropagation();
                  handleContinue();
                }
              }}
            />
            <div className="mt-[16px] flex items-end justify-between">
              <div className="flex gap-[10px]">
                <PrimaryButton type="button" loading={loading} className="w-[200px]" onPress={handleContinue}>
                  Continue
                </PrimaryButton>
              </div>
              <Link
                to="/app/authorize/forgot-passphrase"
                style={{
                  fontSize: 14,
                  color: 'rgba(0, 0, 0, 0.5)',
                  textDecoration: 'none',
                }}
              >
                Forgot passphrase
              </Link>
            </div>
          </Form>
        )}
        {isAuthenticated && (
          <div className={twMerge('flex items-end justify-between', error ? 'mt-[32px]' : 'mt-[16px]')}>
            <div className="flex gap-[10px]">
              <PrimaryButton type="button" ref={launchRef} onPress={handleLaunch} className="w-[200px]">
                Launch
              </PrimaryButton>
            </div>
            <CopyTextButton value={token}>Manually enter token</CopyTextButton>
          </div>
        )}
      </div>
    </Modal>
  );
}
